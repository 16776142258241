import * as React from 'react';
import { memo, useState } from 'react';
import { IAttribute, IAttributeOptions } from 'components/Catalog/Interfaces/IAttribute';
import { ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { empty } from '../../../helpers/empty';
import Configurator, {
    ISelectedAttributes,
    ISelectedSwatch,
    ISwatchConfig,
} from 'components/Catalog/Product/Configurator';

interface IProps {
    config: ISwatchConfig;
    setSelectedProductInformation: (selectedProductInformation: ISelectedProductInformation) => void;
}

const findDefaultOption = (filteredAttribute: IAttribute) => {
    const searchParams = new URLSearchParams(location.search);

    const queryParamValue = searchParams.get(filteredAttribute.code);
    if (empty(queryParamValue)) {
        return filteredAttribute.options.find((option: IAttributeOptions) => option)?.id;
    }

    const selectedOption = filteredAttribute.options.find((option: IAttributeOptions) => option.id === queryParamValue);

    if (!selectedOption || empty(selectedOption)) {
        return filteredAttribute.options.find((option: IAttributeOptions) => option)?.id;
    }

    return selectedOption.id;
};

const ConfiguratorWrapper = (props: IProps) => {
    const { config, setSelectedProductInformation } = props;

    let defaultSelectedMainSwatch;

    const attributes: IAttribute[] = config.options.attributes;

    const indexedAttribute = attributes.reduce((result, attribute: IAttribute) => {
        if (!result[attribute.id]) {
            result[attribute.id] = attribute;
        }
        return result;
    }, {});

    let secondaryAttributes: IAttribute[] = [];
    Object.keys(attributes).filter((attribute: string) => {
        secondaryAttributes.push(attributes[attribute]);
    });
    const selectedDefaults = secondaryAttributes.map((filteredAttribute: IAttribute) => {
        return {
            attributeId: filteredAttribute.id,
            optionId: findDefaultOption(filteredAttribute),
        } as ISelectedAttributes;
    });

    const [selectedAttributes, selectAttributes] = useState<ISelectedAttributes[]>(selectedDefaults);

    if (!defaultSelectedMainSwatch) {
        const firstSelectedDefault = selectedAttributes.find(
            (defaultSelection: ISelectedAttributes) => defaultSelection,
        );

        if (firstSelectedDefault) {
            const attributeId = firstSelectedDefault.attributeId.toString();
            const optionId = firstSelectedDefault.optionId.toString();
            const option = indexedAttribute[attributeId].options.find((option) => option.id.toString() === optionId);

            defaultSelectedMainSwatch = {
                attributeId,
                optionId,
                products: option.products,
            };
        }
    }

    const [defaultSelectedMainSwatchStated, setDefaultSelectedMainSwatch] = useState<ISelectedSwatch>(
        defaultSelectedMainSwatch,
    );

    const selectedOptions = selectedAttributes.map((selectAttribute) => {
        const selectedOption = indexedAttribute[selectAttribute.attributeId].options.find((option) => {
            return option.id === selectAttribute.optionId;
        });

        return {
            optionId: selectAttribute.optionId,
            products: selectedOption.products,
        };
    });

    secondaryAttributes = secondaryAttributes.map((secondaryAttribute: IAttribute) => {
        if (secondaryAttribute.id === defaultSelectedMainSwatchStated?.attributeId) {
            return secondaryAttribute;
        }

        const filteredSecondaryAttributeOptions = secondaryAttribute.options.filter(
            (attributeOption) =>
                attributeOption.products.filter((value) =>
                    selectedOptions.find(
                        (selectedOption) =>
                            selectedOption.products.includes(value) && selectedOption.optionId !== attributeOption.id,
                    ),
                ).length > 0,
        );
        return { ...secondaryAttribute, ...{ options: filteredSecondaryAttributeOptions } };
    });

    if (!defaultSelectedMainSwatch) {
        return <></>;
    }

    return (
        <React.Fragment>
            <Configurator
                defaultSelectedMainSwatch={defaultSelectedMainSwatch}
                setDefaultSelectedMainSwatch={setDefaultSelectedMainSwatch}
                secondaryAttributes={secondaryAttributes}
                selectedAttributes={selectedAttributes}
                selectAttributes={selectAttributes}
                attributes={attributes}
                config={config}
                setSelectedProductInformation={setSelectedProductInformation}
            />
        </React.Fragment>
    );
};

export default memo(ConfiguratorWrapper);
