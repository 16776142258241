import * as React from 'react';

import LayoutForm from 'vkid-ui/lib/Layouts/LayoutForm';
import FormRow from 'vkid-ui/lib/Components/FormRow';
import ControlInput from 'vkid-ui/lib/Components/ControlInput';
import ControlSelect from 'vkid-ui/lib/Components/ControlSelect';
import FormRowGroup from 'vkid-ui/lib/Components/FormRowGroup';
import ButtonIcon from 'vkid-ui/lib/Components/ButtonIcon';
import { useTranslation } from 'react-i18next';
import LayoutFormColumn from 'vkid-ui/lib/Layouts/LayoutFormColumn';
import { IShippingMethod } from '../../../interfaces/checkout/shipping/IShippingMethod';
import { useEffect, useRef, useState } from 'react';
import { IAddress, ICustomer, IGuestAddress } from '../../../interfaces/checkout/customer/ICustomer';
import { IRegionOptionListItem } from '../../../interfaces/checkout/address/IRegionOptionListItem';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import CheckboxWithLabel from 'vkid-ui/lib/Components/CheckboxWithLabel/index';
import { empty } from '../../../helpers/empty';
import { dummyFields } from '../../../components/address/dummyFields';
import { CLIENT_TYPE } from '../../../components/checkout/Payment/PaymentBlock';
import { BaseAddressDefaults } from '../../../components/checkout/Address/defaults/BaseAddressDefaults';
import { IErrorField, IValidationItem, validate, VALIDATION_TYPES } from '../../../helpers/form/validate';
import { BaseAddressRules } from '../../../components/checkout/Address/defaults/BaseAddressRules';
import { AddressFields } from '../../../enums/address/AddressFields';
import { getErrorForField } from '../../../helpers/form/getErrorForField';
import { ContactPhoneAreaItems } from '../../../components/checkout/Address/defaults/ContactPhoneAreaItems';
import { Regions } from '../../../components/checkout/Address/defaults/Regions';
import { extractCodeFromPhone } from '../../../helpers/extractCodeFromPhone';
import useOverlays from '../../../components/overlay/Overlay';
import { useHistory } from 'react-router-dom';
import { SHIPPING_TYPES } from '../../../components/checkout/Shipping/Method/Additional';
import AdsSearch from '../../../components/address/AdsSearch';

export interface IProps {
    proceedAction: () => void;
    selectedMethod: IShippingMethod | null;
    newAddressFields: IGuestAddress | undefined;
    setNewAddressFields: (addressFields: IGuestAddress) => void;
    email: string;
    setEmail: (email: string) => void;
    selectedAddress: IAddress | null;
    selectAddress: (address: IAddress) => void;
    customer: ICustomer | null;
    clientType: CLIENT_TYPE;
}

const NewAddressForm = (props) => {
    const { t } = useTranslation();
    const {
        selectedMethod,
        proceedAction,
        email,
        setEmail,
        selectedAddress,
        selectAddress,
        customer,
        newAddressFields,
        setNewAddressFields,
    } = props;
    const errorDefaults: IErrorField[] = [];
    const errors = useRef(errorDefaults);
    const contactPhoneAreaItems = ContactPhoneAreaItems();
    const [updateStateStatus, updateState] = useState(false);
    const { overlays, closeOverlay } = useOverlays();
    const history = useHistory();
    const overlayOpen = overlays.indexOf('add-address') !== -1;

    const addressDefaults: IGuestAddress = selectedAddress || newAddressFields || BaseAddressDefaults(customer);
    const { phoneCode, telephone } = extractCodeFromPhone(addressDefaults.telephone, addressDefaults.phoneCode);
    addressDefaults.telephone = telephone;
    if (phoneCode) {
        addressDefaults.phoneCode = phoneCode;
    }
    if (selectedMethod?.extra_data?.city_limit) {
        addressDefaults.city = selectedMethod?.extra_data?.city_limit;
    }

    const [fields, setFields] = useState<IGuestAddress>(addressDefaults);

    let regions = Regions;
    if (selectedMethod?.extra_data?.allow_only?.length) {
        regions = regions.filter(
            (regionItem) => !regionItem.value || selectedMethod.extra_data.allow_only.includes(regionItem.value),
        );
    }
    const defaultRegion = regions.find((reg) => reg.value === addressDefaults.region_id);
    const [region, setRegion] = useState(defaultRegion || regions[0]);

    const showField = (fieldName: string) =>
        selectedMethod && (!selectedMethod.visibleFields || selectedMethod.visibleFields.includes(fieldName));

    const changeRegion = (regionItem: IRegionOptionListItem) => {
        setRegion(regionItem);
        setFields({
            ...fields,
            region_id: regionItem.value,
            country_id: regionItem.country_id,
        });
    };
    const validateForm = () => {
        const validationRules: IValidationItem[] = BaseAddressRules();
        validationRules.push({
            field: AddressFields.email,
            validations: [
                { rule: VALIDATION_TYPES.REQUIRED, skip: isLoggedIn },
                { rule: VALIDATION_TYPES.EMAIL, skip: isLoggedIn },
            ],
        });

        errors.current = validate(
            validationRules.filter((rule) => showField(rule.field)),
            fields,
            t,
        );
    };

    const getError = (field) => getErrorForField(field, errors.current);

    const effect = (event) => {
        if (email) {
            fields.email = email;
            setEmail(email);
        }
        validateForm();
        updateState(!updateStateStatus);

        const address = { ...fields, isNew: 1 };
        Object.entries(address).map(([field, value]) => {
            if (empty(address[field])) {
                address[field] = dummyFields[field];
            }
        });
        if (errors.current.length < 1) {
            selectAddress(address);
            if (overlayOpen) {
                history.goBack();
                closeOverlay('add-address');
            }
            if (event.detail) {
                proceedAction();
            }
        }
    };

    const adsSave = (event) => {
        if (event.detail) {
            changeRegion(regions.find((reg) => reg.value === event.detail.region_id));
            if (!!selectedMethod?.extra_data?.city_limit) {
                event.detail.city = fields.city;
            }
            setFields({ ...fields, ...event.detail });
        }
    };

    useEffect(() => {
        if (!empty(fields)) {
            setNewAddressFields(fields);
        }
        if (selectedAddress && errors.current.length < 1) {
            const address = { ...selectedAddress, ...fields };
            selectAddress(address);
        }
    }, [fields]);
    useEffect(() => {
        window.addEventListener('new-address-save', effect);
        window.addEventListener('ads-address-select', adsSave);
        return function cleanup() {
            window.removeEventListener('new-address-save', effect);
            window.removeEventListener('ads-address-select', adsSave);
        };
    });

    return (
        <React.Fragment>
            <LayoutForm layout="vertical">
                {!isLoggedIn && (
                    <LayoutFormColumn>
                        <FormRow
                            label={t('checkout.Email Address')}
                            description={t('checkout.descriptionContactEmail')}
                            required={true}
                            error={getError('email')}
                            labelFor="email"
                        >
                            <FormRowGroup>
                                <ControlInput
                                    id="email"
                                    name="email"
                                    autocomplete={true}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <ButtonIcon
                                    icon={'question'}
                                    intent="link"
                                    tooltip={t('checkout.descriptionOrderConfirm')}
                                />
                            </FormRowGroup>
                        </FormRow>
                    </LayoutFormColumn>
                )}
                <LayoutFormColumn>
                    <React.Fragment>
                        {showField(AddressFields.firstname) && (
                            <FormRow
                                label={t('checkout.Firstname')}
                                required={true}
                                error={getError(AddressFields.firstname)}
                                labelFor="firstname"
                            >
                                <ControlInput
                                    name="firstname"
                                    id="firstname"
                                    autocomplete={true}
                                    value={fields.firstname}
                                    onChange={(e) => setFields({ ...fields, firstname: e.target.value })}
                                />
                            </FormRow>
                        )}
                    </React.Fragment>
                    <React.Fragment>
                        {showField(AddressFields.lastname) && (
                            <FormRow
                                label={t('checkout.Lastname')}
                                required={true}
                                error={getError(AddressFields.lastname)}
                                labelFor="lastname"
                            >
                                <ControlInput
                                    name="lastname"
                                    id="lastname"
                                    autocomplete={true}
                                    value={fields.lastname}
                                    onChange={(e) => setFields({ ...fields, lastname: e.target.value })}
                                />
                            </FormRow>
                        )}
                    </React.Fragment>
                    <React.Fragment>
                        {showField(AddressFields.telephone) && (
                            <FormRow
                                label={t('checkout.Mobile number')}
                                required={true}
                                error={getError(AddressFields.telephone)}
                                labelFor="phone"
                            >
                                <FormRowGroup>
                                    <ControlSelect
                                        value={fields.phoneCode}
                                        onChange={(e) => setFields({ ...fields, phoneCode: e.target.value })}
                                        size="small"
                                        labelClear={t('Clear')}
                                    >
                                        {contactPhoneAreaItems.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </ControlSelect>
                                    <ControlInput
                                        name="phone"
                                        id="phone"
                                        autocomplete={true}
                                        value={fields.telephone}
                                        onChange={(e) => setFields({ ...fields, telephone: e.target.value })}
                                    />
                                    <ButtonIcon
                                        icon={'question'}
                                        intent="link"
                                        tooltip={t('checkout.descriptionDeliveryConfirm')}
                                    />
                                </FormRowGroup>
                            </FormRow>
                        )}
                    </React.Fragment>
                    {window.inAddressConfiguration?.enabled && selectedMethod?.extra_data?.ads_enabled && <AdsSearch />}
                    <React.Fragment>
                        {showField(AddressFields.region_id) && (
                            <FormRow
                                label={t('checkout.County')}
                                required={true}
                                error={getError(AddressFields.region_id)}
                                labelFor="county"
                            >
                                <ControlSelect
                                    label="county"
                                    id="county"
                                    disableSearch={true}
                                    value={region}
                                    onChange={(e) => changeRegion(e.target.value)}
                                    labelClear={t('Clear')}
                                >
                                    {regions.map((regionOption) => {
                                        return (
                                            <option key={regionOption.value} value={regionOption}>
                                                {regionOption.label}
                                            </option>
                                        );
                                    })}
                                </ControlSelect>
                            </FormRow>
                        )}
                        {showField(AddressFields.city) && (
                            <FormRow
                                label={t('checkout.City')}
                                required={true}
                                error={getError(AddressFields.city)}
                                labelFor="city"
                            >
                                <ControlInput
                                    name="city"
                                    id="city"
                                    autocomplete={true}
                                    disabled={!!selectedMethod?.extra_data?.city_limit}
                                    value={fields.city}
                                    onChange={(e) => setFields({ ...fields, city: e.target.value })}
                                />
                            </FormRow>
                        )}
                        {showField(AddressFields.street) && (
                            <FormRow
                                label={t('checkout.Address')}
                                required={true}
                                error={getError(AddressFields.street)}
                                labelFor="street"
                            >
                                <ControlInput
                                    name="street"
                                    id="street"
                                    autocomplete={true}
                                    value={fields.street[0] || ''}
                                    onChange={(e) => setFields({ ...fields, street: [e.target.value] })}
                                />
                            </FormRow>
                        )}
                        {showField(AddressFields.postcode) && (
                            <FormRow
                                label={t('checkout.Postcode')}
                                required={true}
                                error={getError(AddressFields.postcode)}
                                labelFor="postcode"
                            >
                                <ControlInput
                                    name="postcode"
                                    id="postcode"
                                    autocomplete={true}
                                    value={fields.postcode}
                                    onChange={(e) => {
                                        if (selectedMethod && selectedMethod.type === SHIPPING_TYPES.LP_API) {
                                            window.dispatchEvent(
                                                new CustomEvent('shipping-postcode-change', { detail: e.target.value }),
                                            );
                                        }
                                        setFields({ ...fields, postcode: e.target.value });
                                    }}
                                />
                            </FormRow>
                        )}
                    </React.Fragment>
                    {isLoggedIn && showField(AddressFields.street) && (
                        <React.Fragment>
                            <FormRow>
                                <CheckboxWithLabel
                                    label={t('Save in address book')}
                                    checked={!!fields.saveInAddressBook}
                                    onChange={() =>
                                        setFields({ ...fields, saveInAddressBook: !fields.saveInAddressBook })
                                    }
                                />
                            </FormRow>
                        </React.Fragment>
                    )}
                </LayoutFormColumn>
            </LayoutForm>
        </React.Fragment>
    );
};

export default NewAddressForm;
