import FormRow from 'vkid-ui/lib/Components/FormRow/index';
import * as React from 'react';
import { IShippingMethod } from '../../../../interfaces/checkout/shipping/IShippingMethod';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { empty } from '../../../../helpers/empty';
import LayoutForm from 'vkid-ui/lib/Layouts/LayoutForm/index';
import ProceedButton from '../../../../components/checkout/ProceedButton';
import CheckboxWithLabel from 'vkid-ui/lib/Components/CheckboxWithLabel/index';
import ControlInput from 'vkid-ui/lib/Components/ControlInput';
import { IErrorField, IValidationItem, validate, VALIDATION_TYPES } from '../../../../helpers/form/validate';
import { useRef } from 'react';
import { ShippingAdditionalFields } from '../../../../enums/address/ShippingAdditionalFields';
import { getErrorForField } from '../../../../helpers/form/getErrorForField';
import { IAdditional, IAdditionalComponents } from '../../../../components/checkout/Shipping/Method/Additional';

export interface IAdditionalCourier extends IAdditional {
    handOverContactOnly: string | null;
    contactIdCode: string | null;
    handOverUnderAgeForbidden: string | null;
}

interface IProps {
    selectedMethod?: IShippingMethod;
    proceedAction: any;
    resetAction: any;
    setAdditionalData: any;
    shippingAdditional: any | undefined;
    setShippingAdditional: any | undefined;
    continueDisabled: boolean;
    additionalComponents: IAdditionalComponents;
}

const Courier = (props: IProps) => {
    const {
        selectedMethod,
        proceedAction,
        resetAction,
        setAdditionalData,
        setShippingAdditional,
        shippingAdditional,
        additionalComponents,
    } = props;
    const { t } = useTranslation();
    const [contactId, setContactId] = useState('');
    const [noUnderage, setNoUnderage] = useState(false);
    const [handOverContactOnly, setHandOverContactOnly] = useState(false);
    const [requestStateChange, setRequestStateChange] = useState(false);
    const errorDefaults: IErrorField[] = [];
    const errors = useRef(errorDefaults);
    const getError = (field) => getErrorForField(field, errors.current);

    const defaultSelected = useRef(false);

    if (selectedMethod && shippingAdditional) {
        const selectedDefaultItem = shippingAdditional[selectedMethod?.methodCode];
        if (selectedDefaultItem && !defaultSelected.current) {
            defaultSelected.current = true;
            if (contactId !== selectedDefaultItem.contactIdCode) {
                setContactId(selectedDefaultItem.contactIdCode || '');
            }
            if (noUnderage !== selectedDefaultItem.handOverUnderAgeForbidden) {
                setNoUnderage(selectedDefaultItem.handOverUnderAgeForbidden || false);
            }
            if (handOverContactOnly !== selectedDefaultItem.handOverContactOnly) {
                setHandOverContactOnly(selectedDefaultItem.handOverContactOnly || false);
            }
        }
    }

    const validateForm = async () => {
        const validationRules: IValidationItem[] = [
            {
                field: ShippingAdditionalFields.contactId,
                validations: [
                    { rule: VALIDATION_TYPES.REQUIRED, skip: !handOverContactOnly },
                    { rule: VALIDATION_TYPES.MIN_SIZE, value: 11, skip: !handOverContactOnly },
                    { rule: VALIDATION_TYPES.MAX_SIZE, value: 11, skip: !handOverContactOnly },
                    { rule: VALIDATION_TYPES.NUMBER, skip: !handOverContactOnly },
                ],
            },
        ];
        errors.current = validate(validationRules, { contactId }, t);
        setRequestStateChange(true);
    };

    useEffect(() => {
        if (requestStateChange) {
            const additionalData: Partial<IAdditionalCourier> | undefined = {
                handOverContactOnly: handOverContactOnly ? 'true' : null,
                contactIdCode: contactId ? contactId : null,
                handOverUnderAgeForbidden: noUnderage ? 'true' : null,
                courier_info: additionalComponents.courierInfo.exportField,
            };
            if (selectedMethod) {
                shippingAdditional[selectedMethod.methodCode] = additionalData;
                setShippingAdditional(shippingAdditional);
            }
            setRequestStateChange(false);
            if (errors.current.length < 1) {
                setAdditionalData(additionalData);
                proceedAction();
            }
        }
    });

    return (
        <React.Fragment>
            <LayoutForm layout="vertical">
                <FormRow>
                    {additionalComponents.additionalConfig?.showHandoverUnderageCheckbox && (
                        <CheckboxWithLabel
                            label={t('checkout.Handover to underage not allowed')}
                            checked={noUnderage}
                            onChange={() => {
                                setNoUnderage(!noUnderage);
                                resetAction();
                            }}
                        />
                    )}
                    {additionalComponents.additionalConfig?.showHandoverContactCheckbox && (
                        <CheckboxWithLabel
                            label={t('checkout.Handover to contact person only')}
                            checked={handOverContactOnly}
                            onChange={() => {
                                setHandOverContactOnly(!handOverContactOnly);
                                resetAction();
                            }}
                        />
                    )}
                </FormRow>
                {handOverContactOnly && (
                    <FormRow
                        label={t('checkout.Contact person personal ID')}
                        required={true}
                        error={getError(ShippingAdditionalFields.contactId)}
                        labelFor="contactid"
                    >
                        <ControlInput
                            id="contactid"
                            name="contactid"
                            value={contactId}
                            onChange={(e) => {
                                setContactId(e.target.value);
                                resetAction();
                            }}
                        />
                    </FormRow>
                )}
            </LayoutForm>
            {additionalComponents.courierInfo && additionalComponents.courierInfo.component}
            <ProceedButton
                proceedAction={() => validateForm()}
                disabled={(handOverContactOnly && empty(contactId)) || props.continueDisabled}
            />
        </React.Fragment>
    );
};
export default Courier;
