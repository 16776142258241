import * as React from 'react';

import { useEffect, useState } from 'react';
import 'vkid-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import FormRow from 'vkid-ui/lib/Components/FormRow/index';
import { useTranslation } from 'react-i18next';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import ControlInput from 'vkid-ui/lib/Components/ControlInput/index';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import { useSelector } from 'react-redux';
import { empty } from '../../../helpers/empty';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import { IShippingAddress } from '../../../interfaces/checkout/address/IShippingInformationData';
import validatePersonalCode from '../../../helpers/form/validatePersonalCode';
import Buttons from 'vkid-ui/lib/Components/Buttons';
import Button from 'vkid-ui/lib/Components/Button';

interface IProps {
    setPersonalIdSet: (isSet: boolean) => void;
    errors: boolean;
    selectedAddress: IShippingAddress | null;
    personalCodeCallback?: { callbackMethod: (contactId: string) => void };
}
const mapStateToProps = (state) => {
    return {
        personalCode: state.entities.personalCode,
    };
};
const PersonalIdForm = (props: IProps) => {
    const { t } = useTranslation();
    const [contactId, setContactId] = useState('');
    const url = isLoggedIn ? 'carts/mine/personal-code' : `guest-carts/${window.quoteIdMask}/personal-code`;
    useRequest(request({ type: 'personalCode', url }));
    const [{}, setRequest] = useMutation(() =>
        request({ method: 'POST', type: 'setPersonalCode', url, data: { personalCode: contactId } }),
    );
    const { personalCode } = useSelector(mapStateToProps);

    const setContractIdChange = (personalCodeValue: string) => {
        setContactId(personalCodeValue);
        window.dispatchEvent(new CustomEvent('personalCodeChange', { detail: { personalCode: personalCodeValue } }));
    };

    useEffect(() => {
        if (!empty(personalCode)) {
            props.setPersonalIdSet(true);
        }
    }, [personalCode]);

    useEffect(() => {
        if (!empty(contactId)) {
            saveContactId(contactId);
        }
    }, [contactId]);

    const saveContactId = (contactIdValue) => {
        setRequest().then((response) => {
            if (response.body === true || response.body === 'true') {
                props.setPersonalIdSet(contactIdValue.length > 0 && true);
                window.isPersonalIdSet = true;
            } else {
                window.isPersonalIdSet = false;
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
            }
        });
    };

    return (
        <React.Fragment>
            <FormRow
                label={t('checkout.Contract signer personal ID')}
                required={true}
                error={props.errors && empty(contactId) && t('This is a required field')}
                labelFor="id"
            >
                <ControlInput
                    id="id"
                    name="id"
                    value={contactId}
                    autoFocus={!!props?.personalCodeCallback?.callbackMethod}
                    onChange={(e) => {
                        const result: string = validatePersonalCode(e.target.value, props.selectedAddress);
                        setContractIdChange(result);
                    }}
                />
            </FormRow>
            <span dangerouslySetInnerHTML={{ __html: t('I agree to the insurance Terms and Conditions') }} />
            {props?.personalCodeCallback?.callbackMethod && contactId && (
                <React.Fragment>
                    <Buttons layout="vertical-wide">
                        <Button
                            intent={'primary'}
                            title={t('checkout.Pay')}
                            disabled={false}
                            onClick={() => props?.personalCodeCallback?.callbackMethod(contactId)}
                        />
                    </Buttons>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PersonalIdForm;
